<template>
  <StIcon
    :class="color"
    :name="icon"
    class="live-indicator"
    data-t="live-indicator"
    :size="size"
  />
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    isPause?: boolean
    color?: 'white' | 'orange'
    size?: number
  }>(),
  {
    isPause: false,
    color: 'white',
    size: 16,
  },
)

const icon = computed(() => (props.isPause ? 'pause-bold-solid' : 'live'))
</script>

<style scoped>
.live-indicator {
  display: flex;

  &.orange {
    background-color: var(--icon-brand);
  }

  &.white {
    background-color: var(--icon-primary);
  }
}
</style>
